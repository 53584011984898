const Data = [
  {
    department: "Administration & Office",
    person: [
      {
        name: "Dr. Shwe Toe",
        designation: "Reactor",
      },
      {
        name: "Daw Tint Thandar Thu",
        designation: "Registrar",
      },
    ],
  },

  {
    department: "Department of Oral Medicine",
    person: [
      {
        name: "Dr. Zaw Moe Thein",
        designation: "Professor / Head",
      },
      {
        name: "Dr. Moe Thida Htwe",
        designation: "Associate Professor",
      },
      {
        name: "Dr. Nandar Aung",
        designation: "Lecturer",
      },
      {
        name: "Dr. Win Pa Pa Aung",
        designation: "Lecturer",
      },
      {
        name: "Dr. Khin Mya Tun",
        designation: "Assistant Lecturer",
      },
    ],
  },

  {
    department: "Department of Orthodontics",
    person: [
      {
        name: "Dr. Hla Hla Yee",
        designation: "Professor / Head",
      },
    ],
  },

  {
    department: "Department of Paediatric Dentistry",
    person: [
      {
        name: "Dr. Soe Naing",
        designation: "Professor / Head",
      },
      {
        name: "Dr. Ei Ei Myo",
        designation: "Associate Professor",
      },
      {
        name: "Dr. Tin Zar Tun",
        designation: "Assistant Lecturer",
      },
      {
        name: "Dr. Saw Thinzar Pyone",
        designation: "Assistant Lecturer",
      },
      {
        name: "Dr. Kay Khaing Win",
        designation: "Demonstrator",
      },
      {
        name: "Dr. Thiri Hlaing",
        designation: "Demonstrator",
      },
      {
        name: "Dr. Yin Marlar Myo Nyunt",
        designation: "Demonstrator",
      },
    ],
  },


  {
    department: "Department of Periodontology",
    person: [
      {
        name: "Dr. Aung Kyaw Oo",
        designation: "Professor / Head",
      },
      {
        name: "Dr. Ohn Mar Soe",
        designation: "Associate Professor",
      },
      {
        name: "Dr. May Su Yi",
        designation: "Lecturer",
      },
    ],
  },

  {
    department: "Department of Preventive and Community Dentistry",
    person: [
      {
        name: "Dr. Ko Ko Soe",
        designation: "Professor / Head",
      },
      {
        name: "Dr. Maung Maung Myint",
        designation: "Associate Professor",
      },
      {
        name: "Dr. Thein Tun Oo",
        designation: "Associate Professor",
      },
      {
        name: "Dr. Nan Khin Nway Nway Khine",
        designation: "Demonstrator",
      },
    ],
  },


  {
    department: "Department of Prosthodontics",
    person: [
      {
        name: "Dr. Kyaw Tint",
        designation: "Professor / Head",
      },
      {
        name: "Dr. Myat Lay Ko",
        designation: "Associate Professor",
      },
      {
        name: "Dr. Khin Than Kyi",
        designation: "Associate Professor",
      },
      {
        name: "Dr. Yi Yi Soe",
        designation: "Lecturer",
      },
      {
        name: "Dr. Aung Min Naing",
        designation: "Assistant Lecturer",
      },
      {
        name: "Dr. Su Yi Mon Hlaing",
        designation: "Demonstrator"
      }
    ],
  },


];

export default Data;

import React from 'react';
import './App.css';
import {
  Layout,
  Input,
  List,
  Avatar,
  Typography,
  Divider
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import Data from './Data';


function App() {
  const { Header, Footer, Content } = Layout;
  const { Title, Link } = Typography;

  return (
    <div>
      <Header>
        <Input
          placeholder="search"
          allowClear
        />
      </Header>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content>
          <br/>
          <Title level={3}>NUG-banned dental surgeons</Title>

          {
            Data.map( (data: any) =><>
              <List
               grid={{
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 3,
              }}
                header={<Title level={5}>{data.department}</Title>}
                itemLayout="horizontal"
                dataSource={data.person}
                renderItem={(person: any) => (
                  <List.Item >
                    <List.Item.Meta
                      avatar={<Avatar icon={<UserOutlined />} />                    }
                      title={<Link><a href="https://ant.design">{person.name}</a></Link>}
                      description={person.designation}
                    />
                  </List.Item>
                )}
              />
               <Divider />
              </>
            )
          }
          <Footer style={{ textAlign: 'center', }}> No Copyright. No rights reserved. </Footer>
        </Content>
      </Layout>
    </div>
  );
}

export default App;
